<template>
  <div :class="getClass" style="position: relative">
    <v-container style="max-width: 1350px !important">
      <Header />
      <router-view />
    </v-container>
    <v-row class="our-clients mb-0" v-if="getRouteName === 'Company'">
      <v-container style="max-width: 1450px !important" class="mb-0">
        <our-clients />
      </v-container>
    </v-row>
    <v-row class="our-partners mb-0" v-if="getRouteName === 'Home'">
      <v-container style="max-width: 1450px !important" class="mb-0">
        <our-partners />
      </v-container>
    </v-row>
  </div>
</template>
<script>

export default {
  components: {
    OurPartners: () => import("@/components/MainLanding/OurPartners"),
    OurClients: () => import("../components/MainLanding/OurClients"),
    Header: () => import("@/components/MainLanding/Header")
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    getClass() {
      switch (this.getRouteName) {
        case "Features":
          return "normHeightBg bgMainImg";
        case "Pricing":
          return "fullHeightBg bgMainImg";
        case "Home":
          return "fullHeightBg bgMainImg";
        case "ContactUs":
          return "fullHeightBg bgMainImg";
        case "Services":
          return "fullHeightBg bgMainImg";
        case "Blog":
          return "normHeightBg bgMainImg";
        case "BlogDetail":
          return "minHeightBg bgMainImg";
        case "Company":
          return "companyBg";
      }
    },
    getRouteName() {
      return this.$route.name;
    },
  },
};
</script>

<style scoped lang="scss">
.fullHeightBg {
  height: 100% !important;
}

.companyBg {
  height: 62% !important;
  background: linear-gradient(
      270deg,
      rgba(0, 64, 122, 0.85) 0.25%,
      rgba(9, 12, 29, 0.85) 100%
    ),
    url("../assets/img/CompanyBgImg.svg") !important;
  @media (max-width: 960px) {
    height: 100% !important;
  }
}

.minHeightBg {
  height: 148px !important;
  @media (max-width: 960px) {
    height: 300px !important;
  }
}

.normHeightBg {
  height: 380px !important;
  @media (max-width: 780px) {
    height: 320px !important;
  }
  @media (max-width: 780px) {
    height: 280px !important;
  }
}

.bgMainImg {
  background: linear-gradient(
      270deg,
      rgba(0, 64, 122, 0.85) 0.25%,
      rgba(9, 12, 29, 0.85) 100%
    ),
    url("../assets/img/backimg.svg") !important;
}

.our-clients {
  background: url("../assets/img/OurClientBg.png") !important;
}

.bgMainImg,
.companyBg,
.our-clients {
  background-size: cover !important;
}

.our-partners {
  background-color: #dedbe3;
  margin-top: 80px;
}
</style>
